var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { "min-height": "800px" } },
    [
      _c("v-card-title", { staticClass: "justify-center" }, [
        _vm._v("Öğrencilik Bilgilerim"),
      ]),
      _c(
        "v-container",
        [
          !_vm.loading
            ? _c(
                "v-list",
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "v-list-item-title",
                        {
                          staticClass:
                            "d-flex justify-content-between align-center",
                        },
                        [
                          _c("strong", [_vm._v("İsim Soyisim")]),
                          _c(
                            "v-chip",
                            {
                              attrs: {
                                label: "",
                                small: "",
                                outlined: "",
                                color: "primary",
                              },
                            },
                            [_vm._v(_vm._s(_vm.editedItem.FullName))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.editedItem.PhoneNumber
                    ? _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            {
                              staticClass:
                                "d-flex justify-content-between align-center",
                            },
                            [
                              _c("strong", [_vm._v("Telefon Numarası")]),
                              _c(
                                "v-chip",
                                {
                                  attrs: {
                                    label: "",
                                    small: "",
                                    outlined: "",
                                    color: "primary",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.editedItem.PhoneNumber))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.editedItem.ClassLevels
                    ? _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            {
                              staticClass:
                                "d-flex justify-content-between align-center",
                            },
                            [
                              _c("strong", [_vm._v("Okul")]),
                              _c(
                                "v-chip",
                                {
                                  attrs: {
                                    label: "",
                                    small: "",
                                    outlined: "",
                                    color: "amber",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.editedItem.ClassLevels.map(
                                        (s) => s.SchoolName
                                      ).join(", ")
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.editedItem.ClassLevels
                    ? _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            {
                              staticClass:
                                "d-flex justify-content-between align-center",
                            },
                            [
                              _c("strong", [_vm._v("Sınıf Düzeyi")]),
                              _c(
                                "v-chip",
                                {
                                  attrs: {
                                    label: "",
                                    small: "",
                                    outlined: "",
                                    color: "success",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.editedItem.ClassLevels.map(
                                        (s) => s.ClassLevelName
                                      ).join(", ")
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.editedItem.ClassLevels
                    ? _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            {
                              staticClass:
                                "d-flex justify-content-between align-center",
                            },
                            [
                              _c("strong", [_vm._v("Öğrenci Numarası")]),
                              _c(
                                "v-chip",
                                {
                                  attrs: {
                                    label: "",
                                    small: "",
                                    outlined: "",
                                    color: "error",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.editedItem.ClassLevels.map(
                                        (s) => s.StudentNumber
                                      ).join(", ")
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.editedItem.ClassBranches &&
                  _vm.editedItem.ClassBranches.length > 0
                    ? _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            {
                              staticClass:
                                "d-flex justify-content-between align-center",
                            },
                            [
                              _c("strong", [_vm._v("Sınıf Şubeleri")]),
                              _c(
                                "v-chip",
                                {
                                  attrs: {
                                    label: "",
                                    small: "",
                                    outlined: "",
                                    color: "cyan",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.editedItem.ClassBranches.map(
                                        (s) => s.ClassBranchName
                                      ).join(", ")
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.editedItem.Customers &&
                  _vm.editedItem.Customers.length > 0
                    ? _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _c("strong", [_vm._v("Veliler")]),
                          ]),
                          _vm._l(
                            _vm.editedItem.Customers,
                            function (customer, i) {
                              return _c(
                                "p",
                                { key: i, staticClass: "mt-2" },
                                [
                                  _c(
                                    "v-chip",
                                    {
                                      attrs: {
                                        label: "",
                                        small: "",
                                        outlined: "",
                                        color: "green",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(customer.FirstName) +
                                          " " +
                                          _vm._s(customer.LastName) +
                                          " - " +
                                          _vm._s(customer.PhoneNumber)
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.editedItem.Personnels &&
                  _vm.editedItem.Personnels.length > 0
                    ? _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _c("strong", [_vm._v("Danışman Öğretmenler")]),
                          ]),
                          _vm._l(
                            _vm.editedItem.Personnels,
                            function (personnel, i) {
                              return _c(
                                "p",
                                { key: i, staticClass: "mt-2" },
                                [
                                  _c(
                                    "v-chip",
                                    {
                                      attrs: {
                                        label: "",
                                        small: "",
                                        outlined: "",
                                        color: "green",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(personnel.FirstName) +
                                          " " +
                                          _vm._s(personnel.LastName) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }