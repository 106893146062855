<template>
  <v-card min-height="800px">
    <v-card-title class="justify-center">Öğrencilik Bilgilerim</v-card-title>
    <v-container>
      <v-list v-if="!loading">
        <v-list-item-content>
          <v-list-item-title class="d-flex justify-content-between align-center">
            <strong>İsim Soyisim</strong>
            <v-chip label small outlined color="primary">{{ editedItem.FullName }}</v-chip>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-content v-if="editedItem.PhoneNumber">
          <v-list-item-title class="d-flex justify-content-between align-center">
            <strong>Telefon Numarası</strong>
            <v-chip label small outlined color="primary">{{ editedItem.PhoneNumber }}</v-chip>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-content v-if="editedItem.ClassLevels">
          <v-list-item-title class="d-flex justify-content-between align-center">
            <strong>Okul</strong>
            <v-chip label small outlined color="amber">{{
              editedItem.ClassLevels.map((s) => s.SchoolName).join(', ')
            }}</v-chip>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-content v-if="editedItem.ClassLevels">
          <v-list-item-title class="d-flex justify-content-between align-center">
            <strong>Sınıf Düzeyi</strong>
            <v-chip label small outlined color="success">{{
              editedItem.ClassLevels.map((s) => s.ClassLevelName).join(', ')
            }}</v-chip>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-content v-if="editedItem.ClassLevels">
          <v-list-item-title class="d-flex justify-content-between align-center">
            <strong>Öğrenci Numarası</strong>
            <v-chip label small outlined color="error">{{
              editedItem.ClassLevels.map((s) => s.StudentNumber).join(', ')
            }}</v-chip>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-content v-if="editedItem.ClassBranches && editedItem.ClassBranches.length > 0">
          <v-list-item-title class="d-flex justify-content-between align-center">
            <strong>Sınıf Şubeleri</strong>
            <v-chip label small outlined color="cyan">{{
              editedItem.ClassBranches.map((s) => s.ClassBranchName).join(', ')
            }}</v-chip>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-content v-if="editedItem.Customers && editedItem.Customers.length > 0">
          <v-list-item-title>
            <strong>Veliler</strong>
          </v-list-item-title>
          <p class="mt-2" v-for="(customer, i) in editedItem.Customers" :key="i">
            <v-chip label small outlined color="green">
              {{ customer.FirstName }} {{ customer.LastName }} - {{ customer.PhoneNumber }}</v-chip
            >
          </p>
        </v-list-item-content>
        <v-list-item-content v-if="editedItem.Personnels && editedItem.Personnels.length > 0">
          <v-list-item-title>
            <strong>Danışman Öğretmenler</strong>
          </v-list-item-title>
          <p class="mt-2" v-for="(personnel, i) in editedItem.Personnels" :key="i">
            <v-chip label small outlined color="green">
              {{ personnel.FirstName }} {{ personnel.LastName }}
            </v-chip>
          </p>
        </v-list-item-content>
      </v-list>
    </v-container>
  </v-card>
</template>

<script>
import ApiService from '@/core/services/api.service.js'
import moment from 'moment'

export default {
  name: 'profil-duzenle',
  computed: {},
  data() {
    return {
      editedItem: {},
      loading: true
    }
  },
  mounted() {
    this.getUserFromApi()
  },
  filters: {
    moment: function (date) {
      return date ? moment(date).format('DD.MM.YYYY hh:mm') : ''
    }
  },
  methods: {
    getUserFromApi() {
      ApiService.setHeader()
      ApiService.post('api/Student/student-profile')
        .then((data) => {
          this.editedItem = data.data
          this.loading = false
        })
        .catch(({ response }) => {
          ApiService.showError(response)
          this.loading = false
        })
    }
  }
}
</script>
